<template>
    <div class="spi">
        <div class="spiDiv">
<!--            导航-->
<!--            <van-nav-bar fixed style="z-index: 9999" title="" @click-left="leftReturn" left-arrow></van-nav-bar>-->
            <rxNavBar title="" androidOrIOSFlag="true"></rxNavBar>
<!--        标题-->
            <div class="setTittleDiv">
                <img class="setTittleImg" src="../../assets/images/setPersonalInfoImg/SetPersonalInfo-tittle.png" >
                <span class="setTittleText">设置</span>
            </div>
<!--       主体内容  -->
            <div class="setInfoDiv">
                <van-cell-group class="vg">
<!--                    实名认证-->
                    <van-cell  is-link to="" >
                        <div class="leftImgDiv" v-on:click="toRealNameAuthentication" >
                            <img class="leftImg" src="../../assets/images/setPersonalInfoImg/SetPersonalInfo-rnAuthentication.png" alt="">
                            <span class="infoText" >实名认证</span>
                        </div>
                    </van-cell>
                    <!--                    修改密码-->
                    <van-cell  is-link to="" >
                    <div class="leftImgDiv" v-on:click="toAlterPassword('password')">
                        <img class="leftImg" src="../../assets/images/setPersonalInfoImg/SetPersonalInfo-changePassword.png" alt="">
                        <span class="infoText">{{'0'==isPassword ? '设置密码' : '修改密码'}}</span>
                    </div>
                    </van-cell>
                    <!--                    修改管理密码-->
                    <van-cell  is-link to="" >
                        <div class="leftImgDiv" v-on:click="toAlterPassword('managePassword')">
                            <img class="leftImg" src="../../assets/images/setPersonalInfoImg/SetPersonalInfo-changePassword.png" alt="">
                            <span class="infoText">{{'0'==isManagePassword ? '设置管理密码' : '修改管理密码'}}</span>
                        </div>
                    </van-cell>
                    <!--                    更换手机号-->
                    <van-cell  is-link to="" >
                        <div class="leftImgDiv" v-on:click="toAlterPhone">
                            <img class="leftImg" src="../../assets/images/setPersonalInfoImg/SetPersonalInfo-replaceTel.png" alt="">
                            <span class="infoText">更换手机号</span>
                        </div>
                    </van-cell>
                    <!--                    绑定银行卡-->
                    <van-cell  is-link to="" >
                        <div class="leftImgDiv" v-on:click="toBindBankCard">
                            <img class="leftImg" src="../../assets/images/setPersonalInfoImg/SetPersonalInfo-bankCard.png" alt="">
                            <div class="infoText">{{'0'==isBankCode ? '绑定银行卡' : '查看银行卡'}}</div>
                        </div>
                    </van-cell>
                    <!--                    注销账号-->                   
                    <van-cell  v-if="cancel=='0'">
                        <div class="leftImgDiv" v-on:click="toDeleteUser">
                            <img class="leftImg" src="../../assets/images/setPersonalInfoImg/SetPersonalInfo-version.png" alt="">
                            <span class="infoText">注销账号</span>
                        </div>
                    </van-cell>                    
                    <!--                    版本号-->
                    <van-cell  >
                        <div class="leftImgDiv" >
                            <img class="leftImg" src="../../assets/images/setPersonalInfoImg/SetPersonalInfo-version.png" alt="">
                            <span class="infoText">版本号</span>
                            <span class="versionNum">{{ version }}</span>
                        </div>
                    </van-cell>

                    <van-cell  >
                        <div class="leftImgDiv" >
                            <img class="leftImg" src="../../assets/images/setPersonalInfoImg/beianicon.png" alt="">
                            <span class="infoText"></span>
                            <span ><a target="_blank" style="color: #5c6b77" href="https://beian.miit.gov.cn/">{{ '辽ICP备19011878号-3A' }}</a></span>
                        </div>
                    </van-cell>
                </van-cell-group>
            </div>
<!--            注销登录按钮-->
            <div class="cancelLoginDiv" >
<!--                <van-button type="primary" block>注销登录</van-button>-->
                <button class="cancelLoginBtn" type="button" @click="showPopup">注销登录</button>
            </div>
            <van-popup v-model="show"
                       class="popup">
                <div class="popup-head">注销登录</div>
                <div class="popup-central">确认退出账号？</div>
                <div class="popup-tail" @click="logOut">确认</div>
            </van-popup>
            <van-popup v-model="show" :overlay="false" class="forks">
                <div class="fork" @click="hiddenPopup">×</div>
            </van-popup>
        </div>
        <van-overlay :show="clearShow" @click="cleartShow = false">
            <div class="wrapper" @click.stop>
                <div class="block" >
                    <div class="getPassword">
                        确认注销吗？
                    </div>
                    <div class="LockPassWord">
                        <p class="password" @click="clearFinish()">确认 </p>
                    </div>
                </div>
                <div class="closeImg" @click="closeClear">
                </div>
            </div>
        </van-overlay>        
    </div>
</template>

<script>
    import { NavBar,Icon,Cell,CellGroup,Button,Col, Row,Popup,Overlay} from 'vant';
    import {queryPersonDetails,getQueryBaseData,delGlobaluser} from "../../getData/getData";
    import {checkAndroid, checkIOS, getUserId, globaluserId, responseUtil} from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import Cookies from 'js-cookie'
    function logOut(){
        window.himi.logOut()
    }
    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            };
        };
    }
    /********判断H5页面是否在web容器中 start*********/
    export default {
        components: {
            [NavBar .name]:NavBar,
            [Icon .name]:Icon,
            [Cell .name]:Cell,
            [CellGroup .name]:CellGroup,
            [Button .name]:Button,
            [Col .name]:Col,
            [Row .name]:Row,
            [Popup.name]: Popup,
            rxNavBar,
            [Overlay.name]: Overlay,
        },
        data() {
            return {
                //是否展示弹出层
                show: false,
                //是否已绑定银行卡
                isBankCode: '',
                //是否已设置密码
                isPassword: '',
                // 是否已设置管理密码
                isManagePassword:'',
                //是否已实名
                nameAuthentication: '',
                //用户名
                userName:'',
                //银行卡号
                bankCode: '',
                //证件号
                certificateCode: '',
              //证件号类型
              certificateType: '',
                //开户银行
                openingBank: '',
                version:'1.0.0',
				salesDepartmentNo:'',
				openingBankName:'',
                clearShow:false,
                cancel:'',    //是否可以注销
                mobile:''
            }
        },
        created() {
            this.queryPersonDetails()
            this.getQueryBaseData()
        },
        methods:{

            logOut() {
                Cookies.remove('user_id')
                Cookies.remove('staff_id')
                Cookies.remove('token')
                Cookies.remove('user')
                if (openInWebview()) {
                    if(checkAndroid()){
                        try{
                            logOut();
                        } catch (e) {
                            console.log(e.message)
                        }
                    }else if(checkIOS()){
                        window.webkit.messageHandlers.logOut.postMessage({});
                    }
                }else{
                    this.$router.push('test')
                }
            },

            queryPersonDetails(){
                let that = this;
                let queryPersonDetailsData={}
                queryPersonDetailsData.user_id= globaluserId()
                queryPersonDetails(queryPersonDetailsData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let data = response.data.data
                        that.userName = data.userName
                        that.isBankCode = data.isBankCode
                        if(that.isBankCode == 1){
                            that.bankCode = data.bankCode
                            that.openingBank = data.openingBank
							that.salesDepartmentNo = data.salesDepartmentNo
							that.openingBankName = data.openingBankName
                        }
                        that.isPassword = data.isPassword
                      that.isManagePassword = data.isManagePassword
                        that.nameAuthentication = data.nameAuthentication
                        if (that.nameAuthentication){
                          that.certificateType = data.certificateType
                          that.certificateCode = data.certificateCode
                        }
                        that.mobile=data.mobile
                    })
                })

            },
            leftReturn(){
                this.$router.go(-1);
            },
            showPopup() {
              this.show = true
            },
            hiddenPopup() {
                this.show = false
            },
            toRealNameAuthentication:function () {
                if('是'==this.nameAuthentication){
                    this.$router.push({
                        name:'realNameAuthenticationed',
                        query:{
                            userName: this.userName,
                            certificateCode: this.certificateCode,
                          certificateType: this.certificateType
                        }
                    })
                    return
                }
                this.$router.push({
                    name:'RealNameAuthentication',
                    query:{
                    }
                })
            },
            toAlterPassword:function (type) {
                if('0'==this.isPassword && type=='password' || '0'==this.isManagePassword && type=='managePassword'){
                    this.$router.push({
                        name:'setPassword',
                        query:{
                          type:type
                        }
                    })
                    return
                }else if('1'==this.isPassword && type=='password' || '1'==this.isManagePassword && type=='managePassword'){
                    this.$router.push({
                        name: 'alterPassword',
                        params:{
                          userName: this.userName,
                          type:type

                        }
                    })
                }

            },
            toAlterPhone:function () {
                this.$router.push({
                    name:'alterPhone',
                    query:{
                    }
                })
            },
            toBindBankCard:function () {
                if ('0'==this.isBankCode){
                    this.$router.push({
                        name:'bindBankCard',
                        query:{
                        }
                    })
                    return
                }
                this.$router.push({
                    name:'bindBankCardChange',
                    query:{
                    }
                })

            },
            //注销账号
            toDeleteUser:function (){
                this.clearShow=true
            },
            getQueryBaseData(){//字典表接口
                let that =this;
                let _parms = {
                    fdName:[],
                    dbName:['checkWechatLogin'],
                }
                getQueryBaseData(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.cancel=response.data.data.checkWechatLogin[0].dictionaryValue
                    })
                })
            },  
            clearFinish(){
                let that=this
                let _parms={}
                _parms.mobile=that.mobile
                delGlobaluser(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                          that.clearShow=false
                          that.logOut()
                    })
                })
            },
            closeClear(){
                this.clearShow=false
            }       
        }
    }
</script>

<style lang="less" scoped >

    /*弹出层样式*/
    .popup{
        width: 7.2rem;
        height: 5.6rem;
        border-radius: 8px;
        text-align: center;
        color: white;
        font-weight: 900;
    }
    .popup-head{
        height: 1.33rem;
        background-color: orange;
        line-height: 1.33rem;
        font-size: 0.427rem;
    }
    .popup-central{
        height: 2.67rem;
        line-height: 2.67rem;
        font-size: 0.48rem;
        color: black;
    }
    .popup-tail{
        width: 4.8rem;
        height: 0.8rem;
        border-radius: 0.16rem;
        background: linear-gradient(to right, rgb(255, 193, 116), rgb(255, 95, 61));
        line-height: 0.8rem;
        margin-left: 1.2rem;
        font-size: 0.373rem;
    }
    .forks{
        background-color: transparent;
        position: absolute;
        top: 14rem;
    }
    .fork{
        border: white 1px solid;
        border-radius: 50%;
        color: white;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        display:flex;
        justify-content: center;
        align-items: center;

    }

    /*总DIV*/
    .spiDiv{
        width: 94%;
        margin: 0px auto ;
    }
    *{
        margin: 0;
        padding: 0;
    }
/*导航*/

/*    标题DIV*/
    .setTittleDiv{
    }
/*    标题图片*/
    .setTittleImg{
        margin-left: -10px;
    }
/*    标题正文*/
    .setTittleText{
        font-weight: 600;
        font-size: 30px;
        margin-left: 20px;
        display: block;
        margin-top: -52px;
    }
/*    主体内容*/
    .setInfoDiv{
        margin-top: 30px;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
    }
    /*左侧图片样式 */
    .leftImg{
        width: 20px;
        height: 20px;
        margin-bottom: -5px;
        margin-right: 10px;
    }
    /*用户信息内容文本*/
    .infoText{
        display: inline-block;
        font-size: 13px;
        font-weight: bold;
    }
    /*注销按钮DIV*/
    .cancelLoginDiv{
        margin-top: 150px;
        margin-bottom: 40px;
    }
    /*注销按钮*/
    .cancelLoginBtn{
        font-size: 16px;
        width: 100%;
        height: 45px;
        border-radius: 8px;
        border: none;
        background-image: linear-gradient( to right,#FFC274,#FF5D3B);
        font-family: PingFangSC-Semibold;
        color: #FFFFFF;
    }
    /*版本号*/
    .versionNum{
        color: #FF5D3B ;
        margin-left: 220px;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 76%;
        height: 21.5%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    /*    获取密码DIV*/
    .getPassword{
        background: url("../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
    /*    门锁密码*/
    .LockPassWord{
        margin-top: 60px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        font-family: SimSun;
    }
    /*关闭按钮*/
    .closeImg{
        margin-top: 50px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    }
    .van-overlay{
            background-color: rgba(80,80,80,.7);;
    }     
</style>
